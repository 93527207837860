export class Lead {
	constructor() {
		//this.yaCounter = yaCounter52696993;
		//sbjs.init();
	}

	sendZayav(parent) {
		this.send(parent, 29, "lid")
		return false
	}

	send(parent, mid, leadName) {
		//this.yaCounter = "yaCounter52696993";

		$(document).css("cursor", "wait")
		var el = $(parent)
		el.addClass("loader")

		var submit = el.find("input[type='submit']")
		console.log(el.serialize())

		//let src = sbjs.get.current.src + '/' + 	sbjs.get.current.mdm;
		let _y = yaCounter1210720;

		$.ajax({
			url: "/ajax.php?mid=" + mid,
			type: "POST",
			data: el.serialize() + "&str_page=" + $('title').text() + "&mode=send_request",
			dataType: "json",
			beforeSend: function() {
				submit.prop("disabled", true)
			},
			success: function(res) {
				if (res["success"]) {
					// ga('send', 'event', 'lid', leadName, res['id']);
					_y.reachGoal('lid');

					gtag('event', 'zayav',
            {
              'event_category':'lead',
              'event_label': parseInt(res['id'])
            }
          );

          //yaCounter49839871.reachGoal('zayav');


					submit.prop("disabled", true)

					el.html(
						'<div class="success">Спасибо за Вашу заявку. Мы свяжемся с Вами в ближайшее время!</div>'
					)

					$(document).css("cursor", "auto")
					el.removeClass("loader")
				} else {
					alert(
						"Ошибка: " +
							res["error"] +
							". Пожалуйста, повторите попытку или свяжитесь с нами. Приносим извинения за неудобства."
					)
					$(document).css("cursor", "auto")
					el.removeClass("loader")
				}
			},
			error: function(e) {
				console.log(e)
			},
		})
	}
}
