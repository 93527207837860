export class Cart {
	constructor() {
		//this.yaCounter = yaCounter52696993;
		//sbjs.init();
	}

	add2cart(id, org) {
		$.ajax({
			url: "/ajax.php?mid=4",
			type: "POST",
			//context: $('#zayav'),
			data: "action=add&id=" + id + "&ord=" + org,
			dataType: "json",
			success: function(res) {
				//console.log(res.new_count);
				updateHeader(res.new_count)
				//yaCounter8888326.reachGoal('add_to_cart');
			},
			fail: function(e) {
				console.log(e)
			},
		})
	}

	updateHeader(count) {
		console.log(count)
		if (count == null) {
			count = 0
		}
		$("#basket-count").text(count)
	}

	getBasketCount() {
		$.ajax({
			url: "/ajax.php?mid=4",
			type: "POST",
			//context: $('#zayav'),
			data: "action=getBasketCount",
			dataType: "json",
			success: function(res) {
				console.log(res.new_count)
				updateHeader(res.new_count)
			},
			fail: function(e) {
				console.log(e)
			},
		})
	}
}
