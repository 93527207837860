import { Test } from "../_Test/Test.js";
const test = new Test();
test.sayHello();

import { Lead } from "../mod_lead/mod_lead.js";
const lead = new Lead();
window.lead = lead;

import { Cart } from "../mod_cart/mod_cart.js";
const cart = new Cart();
window.cart = cart;

$('.product__nav a.popup').fancybox({
	animationEffect : 'fade',
	i18n : {
		'en' : {
			CLOSE : 'Закрыть'
		}
	}
});
$('.product__nav a.popup').click(function(){
	$('#callback .title').text('Заказать ' + $(this).attr('data-title'));
});


$('input.form-product__input[name=str_phone]').mask("+7 (999) 999-99-99");
$('input#zayav_1_phone').mask("+7 (999) 999-99-99");

$(".aside__catalog-toggle").click(function() {
	$(".aside__content").slideToggle();
	$(this).toggleClass("active");
});

$(".menu-mob__open").click(function() {
	$(".menu-mob__popup").slideDown(200);
});

$(".menu-mob__close").click(function() {
	$(".menu-mob__popup").slideUp(200);
});

$(".slider__list").slick({
	dots: false,
	autoplay: true,
	nextArrow:
		'<button type="button" class="slider__arrow slider__arrow-next" aria-label="Next"></button>',
	prevArrow:
		'<button type="button" class="slider__arrow slider__arrow-previous" aria-label="Previous"></button>',
	infinite: true,
	speed: 400,
	slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				arrows: false,
			},
		},
	],
});

$(".product__image-slider").slick({
	dots: true,
	dotsClass: "product-slider__dots",
	adaptiveHeight: true,
	// asNavFor: '.product__image-popup-slider'
});

// $('.product__image-popup-slider').slick({
//	 asNavFor: '.product__image-slider',
//	 nextArrow: '<button type="button" class="slider__arrow-product slider__arrow-product-next" aria-label="Next"><svg xmlns="http://www.w3.org/2000/svg" width="23.53" height="15.937" viewBox="0 0 23.53 15.937"><path fill="#ffffff" fill-rule="evenodd" d="M1468.78,490.235a1.029,1.029,0,0,0,0-1.422l-6.76-6.965a0.966,0.966,0,0,0-1.39,0,1.009,1.009,0,0,0,0,1.421l5.1,5.25h-19.22a1.005,1.005,0,0,0,0,2.01h19.22l-5.1,5.25a1.009,1.009,0,0,0,0,1.421,0.967,0.967,0,0,0,1.39,0Z" transform="translate(-1445.53 -481.563)"/></svg></button>',
//	 prevArrow: '<button type="button" class="slider__arrow-product slider__arrow-product-previous" aria-label="Previous"><svg xmlns="http://www.w3.org/2000/svg" width="23.53" height="15.937" viewBox="0 0 23.53 15.937"><path fill="#ffffff" fill-rule="evenodd" d="M1468.78,490.235a1.029,1.029,0,0,0,0-1.422l-6.76-6.965a0.966,0.966,0,0,0-1.39,0,1.009,1.009,0,0,0,0,1.421l5.1,5.25h-19.22a1.005,1.005,0,0,0,0,2.01h19.22l-5.1,5.25a1.009,1.009,0,0,0,0,1.421,0.967,0.967,0,0,0,1.39,0Z" transform="translate(-1445.53 -481.563)"/></svg></button>'
// });

$(".aside__content").stickit({ screenMinWidth: 1024 });

$(".catalog__content-tab")
	.not(":first")
	.hide();
$(".catalog__view-btn")
	.click(function() {
		$(".catalog__view-btn")
			.removeClass("active")
			.eq($(this).index())
			.addClass("active");
		$(".catalog__content-tab")
			.hide()
			.eq($(this).index())
			.show();
		if ($(".catalog__models").is(":visible")) {
			$(".catalog__sort").show();
		} else {
			$(".catalog__sort").hide();
		}
	})
	.eq(0)
	.addClass("active");

$(".product__description-tab")
	.not(":first")
	.hide();
$(".product__description-nav .btn")
	.click(function() {
		$(".product__description-nav .btn")
			.removeClass("active")
			.eq($(this).index())
			.addClass("active");
		$(".product__description-tab")
			.hide()
			.eq($(this).index())
			.show();
	})
	.eq(0)
	.addClass("active");

$(".catalog__sort-link").click(function(e) {
	e.preventDefault();
	$(this).toggleClass("active");
	// $(this).toggleClass("reverse");
});

require("../mod_map/mod_map.js");
//require("../mod_lead/mod_lead.js");

