if ($('#map').length)
	ymaps.ready(init);

function init() {
	var map = new ymaps.Map("map", {
		center: [59.913054, 30.274995],
		zoom: 17,
		controls: [],
	});

	var isMobile = {
		Android: function() {
			return navigator.userAgent.match(/Android/i);
		},
		BlackBerry: function() {
			return navigator.userAgent.match(/BlackBerry/i);
		},
		iOS: function() {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
		Opera: function() {
			return navigator.userAgent.match(/Opera Mini/i);
		},
		Windows: function() {
			return navigator.userAgent.match(/IEMobile/i);
		},
		any: function() {
			return (
				isMobile.Android() ||
				isMobile.BlackBerry() ||
				isMobile.iOS() ||
				isMobile.Opera() ||
				isMobile.Windows()
			);
		},
	};

	if (!isMobile.any()) {
		if ($("#map").length) {
			map.controls.add("zoomControl");
		}
	}

	var myPin = new ymaps.Placemark(
		map.getCenter(),
		{
			balloonContent: "Рижский пр. 58, литер Б, оф.2.26",
		},
		{
			// Опции.
			// Необходимо указать данный тип макета.
			iconLayout: "default#image",
			// Своё изображение иконки метки.
			iconImageHref: "/media/_Common/icon_pin.svg",
			// Размеры метки.
			iconImageSize: [40, 60],
			// Смещение левого верхнего угла иконки относительно
			// её "ножки" (точки привязки).
			iconImageOffset: [-20, -60],
		}
	);

	// var addressMap = new ymaps.GeoObject({
	//	 geometry: {
	//		 type: "Point",
	//		 coordinates: [59.913054, 30.274995]
	// 	}
	// }, {
	//	 preset: 'islands#dotIcon',
	// 	iconColor: '#003286'
	// });

	map.geoObjects.add(myPin);
	map.behaviors.disable(["scrollZoom"]);
	map.behaviors.enable(["multiTouch"]);

	if (isMobile.any()) {
		map.behaviors.disable(["drag"]);
	}
}
